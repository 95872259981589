@keyframes headeroverlay {
  from {height: 0;}
  50%  {height: 0;}
  to {height: 90%;}
}

@keyframes bg50 {
  from {width: 0;}
  to {width: 25%;}
}

@keyframes opacity {
  from {opacity: 0;}
  50%  {opacity: 0;}
  75%  {opacity: 0;}
  to {opacity: 1;} 
}
