@import 'global';
@import 'notif';
@import 'anim';

html, * {
  margin: 0; padding: 0;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  z-index: 2;
}

html {
  height: 100%;
}

img {
  max-width: 100%;
}

.hidden {
  display: none;
}

body {
  font-family: 'Work Sans', sans-serif;
  overflow-x: hidden;
  height: 100%;
}

li,ul{list-style:none}
a{text-decoration:none}
button{cursor:pointer}

::placeholder {
  color: #878787;
  font-weight: 500;
}

.top {
  transform: translateY(var(--translateY));
  will-change: transform;
}

.goIndex {
  position: fixed;
  cursor: pointer;
  right: 30px;
  bottom: 30px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(36, 32, 41, 0.6);
  padding: 20px 10px;
  z-index: 100;
  transition: all .3s ease;

  img {
    transform: rotate(-90deg);
    width: 24px;
  }

  &:hover {
    padding: 20px 10px 30px;
    background: rgba(36, 32, 41, 0.9);
  }

  @media screen and (max-width: 768px) {
    right: 10px;
    bottom: 10px;
  }
}
header {
  position: relative;
  background: #AD946C;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 110;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 25%;
    background: #fff;
    animation-name: bg50;
    animation-duration: 1s;
  }

  .headeroverlay {
    background: #F9F9F9;
    height: 90%;
    width: 90%;
    margin: 0;
    position: relative;
    animation-name: headeroverlay;
    animation-duration: 2s;
    height: var(--height);
    will-change: height;

    .discover, .col-lg-6 {
      animation-name: opacity;
      animation-duration: 2s;
      opacity: var(--op);
    	will-change: opacity;
    }
    .row {
      align-items: center;
      position: relative;
      height: 100%;
      text-align: center;

      .col-lg-6 {
        position: relative;
        &:last-of-type {
          &:before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            background: #242029;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
    }

    .menu {
      li {
        a {
          color: #242029;
          font-weight: 200;
          font-size: 54px;
          line-height: 63px;
          position: relative;

          span {
            position: relative;
            z-index: 2;
          }

          &:before {
            content: "";
            display: block;
            height: 0px;
            background: #ad946c;
            top: 50%;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateY(-50%);
            z-index: 1;
            transition: height .3s ease;
          }

          &:hover {
            text-decoration: none;

            &:before {
              height: 20px;
            }
          }

          @media screen and (max-width: 767px) {
            font-size: 30px;
            font-weight: 300;
          }
        }

        &:not(:last-of-type) {
          margin-bottom: 60px;
        }
      }
    }

    @media screen and (max-width: 991px) {
      margin: 0 auto;
      .Logo {
        width: 80%;
        max-width: 500px;
        margin: 20px auto;
      }

      .menu {
        li {
          &:not(:last-of-type) {
            margin-bottom: 25px;
          }
        }
      }
      .row {
        .col-lg-6 {
          &:last-of-type {
            &:before {
              top: -40px;
              transform: translate(-50%);
              left: 50%;
            }
          }
        }
      }
    }
  }
}
  .discover {
    position: absolute;
    bottom: -10px;
    left: 0;
    font-size: 20px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;

    span {
      cursor: pointer;
      transform: translate(0);
      display: inline-block;
      padding-bottom: 50px;
      transition: all .5s ease;
      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 40px;
        background: black;
        position: absolute;
        left: 50%;
        cursor: pointer;
        transition: height .7s ease;
      }
      &:hover {
        transform: translateY(-20px);
        &:after {
          height: 60px;
          transition: height .3s ease;
        }
      }
    }
  }

  .intro {
    max-width: 1120px;
    padding-left: 85px;
    position: relative;
    margin: 45px 0;

    h2,p {
      position: relative;
      z-index: 2;
    }

    h2 {
      font-size: 54px;
      font-family: 'Rozha One', serif;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 3.4px;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 21px;
    }

    &:before {
      font-family: 'Work Sans', sans-serif;
      font-weight: bold;
      font-size: 120px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .05;
      line-height: 90px;
      transform: translateX(var(--translateX));
      will-change: transform;
    }

    &#portfolio {
      &:before {
        content: "PORTFOLIO";
      }
    }
    &#about {
      &:before {
        content: "A PROPOS";
      }
    }
    &#contact {
      &:before {
        content: "CONTACT";
      }
    }

    @media screen and (max-width: 767px) {
      padding-left: 15px;
      padding-right: 15px;
      &:before {
        word-break: break-all;
        opacity: .1;
      }
      h2 {
        font-size: 38px;
        font-weight: 300;
      }
    }
  }

  .ptfl {
    background: rgb(173,148,108);
    background: linear-gradient(135deg, rgba(173,148,108,1) 0%, rgba(173,148,108,1) 40%, rgba(36,32,41,1) 40%, rgba(36,32,41,1) 100%);
    padding-bottom: 14px;
    margin-bottom: 120px;

    .filtres {
      padding: 15px 15px 0 80px;
      position: sticky;
      top: 0px;
      z-index: 50;
      background: #ad946c;
      width: auto;
      display: inline-block;
      span {
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        color: white;
        cursor: pointer;
        font-size: 16px;

        &:hover, &.active {
          &:after {
            content: "";
            display: block;
            width: 35px;
            height: 2px;
            background: white;
            margin: 0 auto;
          }
        }
        &:not(:first-of-type){
          &:before {
            content: "•";
            display: inline-block;
            margin: 0 10px;
          }
          &:after {
            transform: translateX(10px);
          }
        }
      }
      @media screen and (max-width: 767px) {
        padding-left: 20px;
      }
    }
    .pj {
      margin-top: 14px;
      padding: 0px 7px;
    }
    article {
      position: relative;
      cursor: pointer;

      > img {
        width: 100%;
      }

      &:after {
          content: "";
          display: block;
          position: absolute;
          right: 22px;
          bottom: 20px;
          z-index: 11;
          background: url(../img/ptfl/arrow.png);
          width: 37px;
          height: 23px;
      }
      .ptfloverlay {
        background: rgba(36,32,41,.9);
        color: white;
        position: absolute;
        bottom: 5px;
        right: 12px;
        left: 12px;
        padding: 5px 60px 5px 5px;
        z-index: 10;
      }

      h3, h4, p {
        margin: 0;
      }

      h3 {
        text-transform: uppercase;
        font-size: 37px;
        font-weight: 200;
      }
      .techs {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;

        span {
          display: inline-block;

          &:not(:first-of-type):before {
            content: "•";
            display: inline-block;
            margin: 0 10px;
          }
        }
      }
      .tags {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 300;
        margin-top: -5px;
        margin-bottom: 10px;
        display: none;

        span {
          display: inline-block;

          &:before {
            content: "#";
            display: inline-block;
          }
          &:not(:first-of-type):before {
            margin: 0 0 0 5px;
          }
        }
      }
      h4 {
        font-size: 14px;
        font-weight: 400;
        display: none;
      }

      &:hover {
        h4, .tags {
          display: block;
        }
      }
      @media screen and (max-width: 1100px) {
        &:hover {
          h4, .tags {
            display: none;
          }
        }
      }
    }


    @media screen and (max-width: 767px) {
      margin-bottom: 60px;
      article {
        h3 {
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 2px;
        }
      }
    }
  }

  .cv {
    padding-left: 80px;
    h3 {
      font-size: 40px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 500;
    }

      .formation {
        background: #F9F9F9;
        margin-left: -80px;
        padding: 20px 15px 20px 80px;
        margin-right: -20px;
        margin-top: 40px;

        div {
          padding: 10px;
          &:nth-child(1) {
            padding-right: 40px;
          }
          &:nth-child(2) {
            padding: 10px 40px;
            border-left: 2px solid #ad946c;
            border-right: 2px solid #ad946c;
          }
          &:nth-child(3) {
            padding-left: 40px;
          }
        }
      }
      .experience {
        h3 {
          margin: 50px 0;
        }
      }
      .experience, .formation {
        .row {
          margin-bottom: 20px;
          div[class*="col-lg"] {
            h4 {
              font-size: 22px;
              font-weight: 500;
              line-height: 25px;
              text-transform: uppercase;
            }
            span {
              font-size: 15px;
              font-weight: 400;
              text-transform: uppercase;
            }
            small {
              font-size: 15px;
              font-weight: 300;
              em {
                font-style: normal;
                &:before {
                  content: "•";
                  display: inline-block;
                  margin: 0 10px 0 15px;
                }
              }
            }
          }
          > div {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            flex-direction: column;
          }
          > ul {
            border-left: 2px solid #AD946C;

            li {
              &:before {
                content: "•";
                display: inline-block;
                margin: 0 10px 0 15px;
              }
            }
          }
        }
      }

    @media screen and (max-width: 767px) {
      padding-left: 15px;

      h3 {
        font-size: 24px;
      }
    }
    @media screen and (max-width: 991px) {
      .experience, .formation {
        .row {
          margin-bottom: 0;
          div[class*="col-lg"] {
            h4 {
              font-size: 18px;
              line-height: 20px;
            }
            span {
              font-size: 14px;
            }
            small {
              font-size: 14px;
            }
          }
          > ul {
            border-left: 0px;
            border-top: 2px solid #AD946C;
            margin-bottom: 40px;
            margin-top: 10px;
            padding-top: 10px;
          }
        }
      }
      .formation {
        margin-left: 0;
        padding: 20px 15px;
        margin-right: 0;

        div {
          padding: 40px 20px!important;
          &:nth-child(2) {
            margin: 20px 0;
            border-left: 0px solid #ad946c;
            border-right: 0px solid #ad946c;
            border-top: 2px solid #ad946c;
            border-bottom: 2px solid #ad946c;
          }
        }
      }
    }
  }

  .skills {
    padding: 100px 0;

    h3 {
      padding-bottom: 20px;
    }
    p {
      padding: 20px 0;
      font-size: 22px;
      span {
        padding-left: 20px;
        font-weight: 300;
      }
    }
    .dl-cv {
      transform: translateY(-80px);
      a {
        background: #242029;
        border-radius: 50%;
        height: 460px;
        width: 460px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 30px;
        color: white;
        font-weight: 300;
        line-height: 29px;
        letter-spacing: 1px;
        position: relative;

        span {
          position: relative;
          z-index: 2;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 40px; right: 40px; left: 40px; bottom: 40px;
          border-radius: 50%;
          background: #ad946c;
          transform: scale(0);
          transition: transform .3s linear;
        }

        &:hover {
          text-decoration: none;

          &:before {
            transform: scale(1);
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      p {
        font-size: 16px;
        padding: 10px 0;
        span {
          padding-left: 15px;
        }
      }
      .dl-cv {
        a {
          background: #242029;
          height: 300px;
          width: 300px;
          font-size: 20px;
          line-height: 19px;
        }
      }
    }
    @media screen and (max-width: 1079px) {
      .dl-cv {
        transform: translateY(0);
      }
    }
  }

  #footer {
    background: #242029;
    position: relative;
    height: 430px;
    padding-top: 86px;
    margin-right: -15px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -260px;
      right: 0;
      left: 0;
      bottom: 0;
      background: #ad946c;
      transform: skewX(-85deg);
    }
    .form-contact {
      position: relative;
      z-index: 2;
      background: #ad946c;
      height: calc(100% - 30px);
      justify-content: center;
      align-items: flex-end;

      a {
        color: #242029;
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
      }
    }
    @media screen and (max-width: 997px) {
      height: auto;
      min-height: 430px;
      padding-top: 15px;

      &:before {
        width: 0;
        height: 0;
        top: 0px;
        transform: skewX(0deg);
      }

      .form-contact {
        flex-direction: column-reverse;
        > * {
          padding: 0 35px;
        }
        ul li {
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
        }
      }
    }
  }
